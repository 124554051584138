<template>
	<div class="title-box flex f-align f-justifyB" :class="{'f-center': pageUrl == 'index'}">
		<div class="img-home flex f-align" @click="backHome()" v-show="pageUrl != 'index'">
			<img src="../assets/image/qt-fh@2x.png">
		</div>
		<span class="text-c">青柠智能洗衣</span>
		<div class="time-box" v-show="pageUrl != 'index'">
			{{timeVal}}s
		</div>
	</div>
</template>

<script>
	export default {
		name: 'customTitle',
		props: {
			text_c: String,
			pageUrl: String
		},
		data() {
			return {
				timer: '',
				timeVal: 60
			}
		},
		methods: {
			backHome() {
				clearInterval(this.timer)
				if (!this.pageUrl) {
					this.$router.replace('/')
				} else {
					this.$router.replace(this.pageUrl)
				}
			},
			init() {
				clearInterval(this.timer)
				this.timer = setInterval(() => {
					this.timeVal--
					if (this.timeVal == 0) {
						this.$router.replace('/')
						clearInterval(this.timer)
					}
				}, 1000)
			}
		}
	}
</script>

<style scoped lang="scss">
	.title-box {
		width: 100%;
		height: 110px;
		background-color: #FFFFFF;
		padding: 0 40px;


		.img-home {
			width: 36px;
			height: 42px;

			img {
				width: 20px;
				height: 36px;
			}
		}

		.time-box {
			width: 36px;
			height: 42px;
			background-image: url(../assets/image/qt-mb@2x.png);
			background-size: 100% 100%;
			font-size: 14px;
			text-align: center;
			line-height: 48px;
		}

		.text-c {
			font-size: 35px;
			font-weight: bold;
			line-height: 49px;
		}
	}
</style>
