<template>
	<div class="app-box">
		<!--    <div style="padding-top: 20px;font-size: 15px;padding-left: 15px;padding-right: 15px;display: flex;justify-content: space-between">-->
		<!--      <div>欢迎使用智能洗衣柜</div>-->
		<div class="flex f-align f-justifyB screen-top">
			<div class="flex f-align">
				<!-- <img src="../assets/image/sy-long@2x.png" style="width: 5rem;height: 4rem;">
          <div class="flex f-column white ml10">
            <span class="font26" style="letter-spacing: 10px;">智能洗护专家</span>
            <span class="font20">Huanxiaodi Nursing specialist</span>
          </div> -->
			</div>
			<div class="flex f-align white">
				<span class="font60">{{ nowTime }}</span>
				<span
					class="ml10 mr10"
					style="width: 1px; height: 35px; background: #ffffff"
				></span>
				<div class="flex f-column f-align">
					<span class="font26">{{ nowDate }}</span>
					<span class="font22">{{ nowWeek }}</span>
				</div>
			</div>
		</div>
		<!--    </div>-->
		<!--           :controls="false"-->

		<video
			v-if="false"
			id="video"
			autoplay
			loop
			controls
			muted
			:show-progress="false"
			style="width: 100%; height: 430px; background-color: #000"
			:show-fullscreen-btn="false"
			:enable-progress-gesture="false"
		>
			<source :src="video || defaultVideo" type="video/mp4" />
		</video>

		<div class="screen">
			<div
				v-if="advertNei.length == 0"
				class="banner-img"
				style="background-color: #3063d9"
			></div>
			<van-swipe
				v-else
				class="my-swipe"
				:autoplay="3000"
				indicator-color="white"
			>
				<van-swipe-item v-for="(item, index) in advertNei" :key="item.id">
					<img class="banner-img" :src="item" :mode="center" />
				</van-swipe-item>
			</van-swipe>
			<!--			<div class="flex f-align f-justifyB screen-top">-->
			<!--				<div class="flex f-align">-->
			<!--					&lt;!&ndash; <img src="../assets/image/sy-long@2x.png" style="width: 5rem;height: 4rem;">-->
			<!--					<div class="flex f-column white ml10">-->
			<!--						<span class="font26" style="letter-spacing: 10px;">智能洗护专家</span>-->
			<!--						<span class="font20">Huanxiaodi Nursing specialist</span>-->
			<!--					</div> &ndash;&gt;-->
			<!--				</div>-->
			<!--				<div class="flex f-align white">-->
			<!--					<span class="font60">{{nowTime}}</span>-->
			<!--					<span class="ml10 mr10" style="width: 1px;height: 35px;background: #FFFFFF;"></span>-->
			<!--					<div class="flex f-column f-align">-->
			<!--						<span class="font26">{{nowDate}}</span>-->
			<!--						<span class="font22">{{nowWeek}}</span>-->
			<!--					</div>-->
			<!--				</div>-->
			<!--			</div>-->
		</div>
		<custom-title ref="customTitle" :pageUrl="pageUrl"></custom-title>
		<div class="bottom-box">
			<router-view
				@change="viewChange"
				:gzbh="gzbh"
				:phone="phone"
				:address="address"
			></router-view>
		</div>

		<i
			v-if="$route.path == '/index'"
			@click="setHandle()"
			class="el-icon-setting set-icon"
		></i>

		<el-dialog
			v-if="centerDialogVisible"
			title="请输入机柜编号"
			top="6vh"
			:visible.sync="centerDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			width="95%"
			center
			:modal-append-to-body="false"
		>
			<div class="f-center">
				<el-input
					v-model="formData.gzbhInput"
					@click.native="clickHandle($event, 'gzbhInput')"
					placeholder="请输入机柜编号"
				>
				</el-input>
			</div>
			<key-board
				ref="keyBoard"
				@change="boardChange"
				:max="11"
				@complete="confirmGzbh"
				boardType="large"
			>
			</key-board>
		</el-dialog>
	</div>
</template>

<script>
import axios from 'axios'
import qs from 'querystring'
import keyBoard from '../components/keyBoard.vue'
import { arrayEqual } from '../utils/tool.js'
import customTitle from '../components/customTitle.vue'

const defaultVideo =
	'https://qingningxiyi.oss-cn-hangzhou.aliyuncs.com/default_video.b6f7deb0.mp4'

export default {
	data() {
		return {
			sysTimer: '',
			nowDate: null, //存放年月日变量
			nowTime: null, //存放时分秒变量
			nowWeek: null,
			cabtype: '', // 平台类型
			str: '',
			timer2: '', // 内屏广告
			timer3: '', // 外屏广告获取
			timer4: '', // 开锁
			timer5: '', // 版本
			timer6: '', // 机柜上报网络状态
			centerDialogVisible: false,
			formData: {
				gzbhInput: ''
			},
			pageUrl: '',
			timeNum: 0,
			gzbh: '',
			stopddd: '',
			phone: '',
			address: '',
			advertNei: [],
			advertWai: [],
			loadTimer: '',
			loadTime: 5,
			video: '',
			defaultVideo
		}
	},
	components: {
		keyBoard,
		customTitle
	},
	created() {
		this.sysTimer = setInterval(this.getTime, 1000)
	},
	mounted() {
		this.getGzPlatform()
		// window.addEventListener('click', () => {
		// 	this.initVideo()
		// })
	},

	methods: {
		// initVideo() {
		// 	const video = document.getElementById('video')
		// 	this.$nextTick(() => {
		// 		video.muted = false
		// 		video.play()
		// 	})
		// },
		viewChange(str) {
			this.pageUrl = str
			if (str == 'index') return
			if (this.$route.name == 'boxDoorOpen') {
				this.$refs.customTitle.timeVal = 20
			} else {
				this.$refs.customTitle.timeVal = 60
			}
			this.$refs.customTitle.init()
		},
		setHandle() {
			this.$router.push('./quCode?type=sys')
		},
		confirmGzbh() {
			if (!this.formData.gzbhInput) {
				this.$message({
					message: '请输入机柜编号',
					type: 'error',
					offset: 200
				})
				return
			}

			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})

			this.$post('/Cabinet/activateGzbh', {
				gzbh: this.formData.gzbhInput
			}).then((res) => {
				if (res.code == 200) {
					loading.close()
					if (this.cabtype == 'windows') {
						this.setGzbhWindows()
					} else {
						AndroidInterFace.setNumber(this.formData.gzbhInput)
						this.loadTimer = setInterval(() => {
							this.loadTime--
							if (this.loadTime == 0) {
								clearInterval(this.loadTimer)
								AndroidInterFace.reload()
							}
						}, 1000)
						this.$alert(`激活成功，程序将在${this.loadTime}秒后重启`, '提示', {
							confirmButtonText: '立即重启',
							showClose: false,
							callback: (action) => {
								clearInterval(this.loadTimer)
								AndroidInterFace.reload()
							}
						})
					}
				} else {
					loading.close()
					this.$refs.keyBoard.focusIndex = 0
					this.$refs.keyBoard.commonStr = ''
					this.formData.gzbhInput = ''
					this.$message({
						message: res.msg,
						type: 'error',
						offset: 200
					})
				}
			})
		},
		setGzbhWindows() {
			axios
				.post(
					this.$api + '/cabinet/setNumber',
					qs.stringify({
						gzbh: this.formData.gzbhInput
					})
				)
				.then((res) => {
					if (res.data.code == 0) {
						this.$router.go(0)
					} else {
						this.formData.gzbhInput = ''
						this.$message({
							message: res.msg,
							type: 'error',
							offset: 200
						})
					}
				})
		},
		boardChange(str) {
			this.formData.gzbhInput = str
		},
		clickHandle(event, type) {
			this.$refs.keyBoard.focusIndex = event.target.selectionStart
			this.$refs.keyBoard.commonStr = this.formData[type]
		},
		// 获取柜子信息
		getGzInfo(gzbh) {
			this.$post('/Cabinet/list', {
				gzbh: gzbh
			}).then((res) => {
				console.log('获取柜子信息')
				console.log(res)
				if (
					res.code == 0 &&
					res.data.length > 0 &&
					res.data[0].macAdd == '已激活'
				) {
					let data = res.data[0]
					this.gzbh = data.gzbh
					this.phone = data.phone
					this.address = data.address
					this.video = data.video
					localStorage.setItem('gzbh', data.gzbh)
					localStorage.setItem('phone', data.phone)
					localStorage.setItem('address', data.address)
					localStorage.setItem('merchantId', data.operatorid)

					// if (this.stopddd==''){
					//
					//   window.location.reload();
					// }
					// this.stopddd = '1';
					this.init()
				} else {
					this.centerDialogVisible = true
					localStorage.clear()
				}
			})
		},
		// 获取柜子系统
		getGzPlatform() {
			try {
				var macadds = AndroidInterFace.getNumber()
				if (!macadds) {
					this.centerDialogVisible = true
					localStorage.clear()
				} else {
					this.getGzInfo(macadds)
				}
				this.cabtype = 'android'
				localStorage.setItem('cabtype', 'android')
			} catch (e) {
				localStorage.setItem('cabtype', 'windows')
				if (process.env.NODE_ENV == 'development') {
					this.getGzInfo('XL06280019')
				} else {
					axios.post(this.$api + '/cabinet/getNumber').then((res) => {
						if (res.data.code == 0) {
							this.getGzInfo(res.data.data.gzbh)
						} else {
							this.centerDialogVisible = true
							localStorage.clear()
						}
					})
				}
			}
		},
		// 获取时间
		getTime() {
			const date = new Date()
			let month = date.getMonth() + 1
			let day = date.getDate()
			let hour = date.getHours()
			let minute = date.getMinutes()
			let weekDay = date.getDay()
			if (this.hour > 12) this.hour -= 12
			month = check(month)
			day = check(day)
			hour = check(hour)
			minute = check(minute)

			function check(i) {
				const num = i < 10 ? '0' + i : i
				return num
			}
			this.nowDate = month + '月' + day + '日'
			this.nowTime = hour + ':' + minute
			this.nowWeek =
				'星期' + ['日', '一', '二', '三', '四', '五', '六'][weekDay]
		},
		init() {
			clearTimeout(this.timer2)
			clearTimeout(this.timer3)
			clearTimeout(this.timer4)
			clearTimeout(this.timer5)
			clearTimeout(this.timer6)
			// this.getLoc();
			this.getAdvertWai()
			this.getAdvertNei()
			// this.getVersion()
			this.gzbhLine()
		},
		// 上报机柜网络状态
		gzbhLine() {
			this.$post('/Cabinet/detectionGzbh', {
				gzbh: localStorage.getItem('gzbh')
			})
				.then((res) => {
					clearTimeout(this.timer6)
					this.timer6 = setTimeout(() => {
						this.gzbhLine()
					}, 1000 * 60 * 3)
				})
				.catch(() => {
					clearTimeout(this.timer6)
					this.timer6 = setTimeout(() => {
						this.gzbhLine()
					}, 1000 * 60 * 3)
				})
		},
		// 获取版本信息
		getVersion() {
			this.$post('/Version/list')
				.then((res) => {
					if (res.code == 0) {
						let newV = res.data[0].versionNumber.split('.')
						let oldV = this.$version.split('.')
						let newN = parseInt(newV.join(''))
						let oldN = parseInt(oldV.join(''))
						if (newN > oldN) {
							localStorage.clear()
							sessionStorage.clear()
							this.$router.replace('/')
							location.reload()
						}
					}
					clearTimeout(this.timer5)
					this.timer5 = setTimeout(() => {
						this.getVersion()
					}, 1000 * 5 * 60)
				})
				.catch(() => {
					clearTimeout(this.timer5)
					this.timer5 = setTimeout(() => {
						this.getVersion()
					}, 1000 * 5 * 60)
				})
		},
		// 获取开锁信息
		getLoc() {
			if (localStorage.getItem('cabtype') == 'android') {
				this.$post('/Box/getMsg', {
					gzbh: localStorage.getItem('gzbh')
				})
					.then((res) => {
						if (res.code == 200) {
							var data = res.openlock
							AndroidInterFace.open(data.portname, data.opencmd)
						}
						clearTimeout(this.timer4)
						this.timer4 = setTimeout(() => {
							this.getLoc()
						}, 1000)
					})
					.catch(() => {
						clearTimeout(this.timer4)
						this.timer4 = setTimeout(() => {
							this.getLoc()
						}, 1000)
					})
			}
		},
		// 获取内屏广告
		getAdvertNei() {
			this.$post('/Advertising/list', {
				gzbh: localStorage.getItem('gzbh'),
				type: '屏内'
			})
				.then((res) => {
					console.log('屏内')
					console.log(res)
					if (res.code == 0) {
						let arr = res.data.map((item) => {
							return item.advertisingPath
						})
						if (!arrayEqual(arr, this.advertNei)) {
							this.advertNei = arr
						}
					}
					clearTimeout(this.timer2)
					this.timer2 = setTimeout(() => {
						this.getAdvertNei()
					}, 1000 * 60 * 10)
				})
				.catch(() => {
					clearTimeout(this.timer2)
					this.timer2 = setTimeout(() => {
						this.getAdvertNei()
					}, 1000 * 60 * 10)
				})
		},
		// 获取外屏广告
		getAdvertWai() {
			this.$post('/Advertising/list', {
				gzbh: localStorage.getItem('gzbh'),
				type: '屏外'
			})
				.then((res) => {
					if (res.code == 0) {
						let arr = res.data.map((item) => {
							return item.advertisingPath
						})
						if (!arrayEqual(arr, this.advertWai)) {
							this.advertWai = arr
							localStorage.setItem('advertWai', JSON.stringify(this.advertWai))
						}
					}
					clearTimeout(this.timer3)
					this.timer3 = setTimeout(() => {
						this.getAdvertWai()
					}, 1000 * 60 * 10)
				})
				.catch(() => {
					clearTimeout(this.timer3)
					this.timer3 = setTimeout(() => {
						this.getAdvertWai()
					}, 1000 * 60 * 10)
				})
		}
	}
}
</script>

<style scoped lang="scss">
.app-box {
	width: 100%;
	height: 100%;

	.screen {
		width: 100%;
		height: 960px;
		position: relative;

		.banner-img {
			width: 100%;
			height: 960px;
		}

		.screen-top {
			position: absolute;
			width: 1080px;
			padding: 20px;
			top: 0;
			left: 0;
			z-index: 99;
		}
	}

	.bottom-box {
		width: 100%;
		height: 850px;
		padding: 40px 40px 0;
		background: #3063d9;
	}
}

.input-ref {
	position: fixed;
	left: -1000px;
	top: 0;
}

::v-deep .el-input {
	width: 50%;
	font-size: 36px;
	margin-bottom: 20px;

	.el-input__inner {
		height: 90px;
		line-height: 90px;
		border-radius: 10px;
	}
}

::v-deep .el-dialog__header {
	padding-top: 50px;

	.el-dialog__title {
		font-size: 36px;
		line-height: 36px;
		font-weight: bold;
	}

	.el-dialog__headerbtn {
		font-size: 30px;
	}
}

::v-deep .el-dialog__body {
	padding: 30px 0 0;

	.el-button--default {
		width: 120px;
		height: 60px;
		border: 1px solid #999999;
		border-radius: 5px;
		font-size: 20px;
		color: #999999;
	}

	.el-button--primary {
		width: 120px;
		height: 60px;
		background: #3063d9;
		border-radius: 5px;
		font-size: 20px;
		color: #ffffff;
	}
}

.set-icon {
	font-size: 50px;
	color: #ffffff;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
}
</style>
